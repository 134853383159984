<template>
  <v-card elevation="6" class="mx-auto mt-10" width="400">
    <!-- Formulario de inicio de sesión -->
    <v-form
      ref="login-form"
      lazy-validation
      class="pa-md-2 mx-lg-auto"
      @submit.prevent="loginForm"
      v-if="!changePassword"
      v-show="!userTokenRequest && !userGenerateQR"
    >
      <v-text-field
        class="ma-3"
        v-model="user.username"
        :rules="rules.required"
        label="Usuario"
        required
      ></v-text-field>

      <v-text-field
        v-model="user.password"
        class="ma-3"
        :rules="rules.required"
        label="Contraseña"
        required
        :type="show1 ? 'text' : 'password'"
        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="show1 = !show1"
      ></v-text-field>
      <transition name="fade">
        <v-alert v-if="validationError" dense elevation="2" type="warning"
          >Usuario o contraseña incorrectos.</v-alert
        >
      </transition>
      <v-btn
        :loading="isLoading"
        class="ma-3"
        color="#113c57"
        grou
        dark
        type="submit"
        rounded
      >
        Ingresar
      </v-btn>
    </v-form>
    <!-- form para ingresar el token con el qr ya leido -->
    <div class="text-center" v-if="userTokenRequest">
      <img
        src="@/assets/token-icon.svg"
        alt="TokenLock"
        class="small-logo"
        height="80px"
        width="80px"
      />
      <h2 class="pt-3" style="font-size: 1em">
        Ingresar código de autenticación
      </h2>
      <v-form
        v-model="isTokenFormValid"
        ref="token-form"
        id="token-form"
        @submit.prevent="tokenForm()"
        class="text-left pt-8"
      >
        <v-row class="justify-center align-center">
          <v-col cols="6" md="6" sm="12" class="py-0">
            <v-text-field
              tabindex="1"
              outlined
              dense
              placeholder=""
              class="input-centered"
              v-mask="'######'"
              :rules="
                rules.required.concat([rules.minMaxNumber(token, 1000, 999999)])
              "
              label="Token"
              type="text"
              v-model="token"
            />
          </v-col>
        </v-row>

        <div class="pl-12 pr-12" v-if="tokenError">
          <transition name="fade">
            <v-alert dense elevation="2" type="warning"
              >Código incorrecto. Intente nuevamente.</v-alert
            >
          </transition>
        </div>

        <v-row class="justify-center align-center">
          <v-col cols="6" md="6" sm="12" class="text-center mb-4">
            <v-btn
              tabindex="2"
              :loading="isLoadingTokenBtn"
              color="primary"
              :disabled="!isTokenFormValid"
              type="submit"
              form="token-form"
              >Confirmar</v-btn
            >
          </v-col>
        </v-row>

        <v-divider></v-divider>
        <p
          class="rubic text-green font-weight-bold title-sign"
          style="font-size: 0.8em; text-align: center; cursor: pointer"
          @click="backToLogin()"
        >
          Volver al inicio de sesión
        </p>
      </v-form>
    </div>
    <!-- form para pedir qr para 2fa -->
    <div class="text-center" v-if="userGenerateQR">
      <h2 class="rubic text-green pt-2" style="font-size: 1em">
        {{
          appSelected == null
            ? "Elegir aplicación generadora de QR"
            : "Escanear QR en la aplicación de " + appSelected
        }}

        <v-row v-if="appSelected == null">
          <v-col cols="12" class="pb-0 pt-10"
            ><v-btn
              color="blue-grey"
              dark
              rounded
              small
              @click="appSelected = 'Microsoft'"
            >
              Microsoft Authenticator</v-btn
            ></v-col
          >
          <v-col cols="12" class="pb-0 pt-10"
            ><v-btn
              color="blue-grey"
              dark
              rounded
              small
              @click="appSelected = 'Google'"
            >
              Google Authenticator</v-btn
            ></v-col
          >
        </v-row>
        <img
          v-if="appSelected != null"
          :src="qrCode"
          alt="qrCode"
          height="200px"
          width="200px"
        />
      </h2>
      <v-btn
        color="primary"
        v-if="appSelected != null"
        @click="goToAuthenticateCode()"
        >Siguiente</v-btn
      >
      <v-col cols="12" class="py-0 pt-10">
        <v-divider></v-divider>
        <p
          class="rubic text-green font-weight-bold title-sign"
          style="font-size: 0.8em; text-align: center; cursor: pointer"
          @click="backToLogin()"
        >
          Volver al inicio de sesión
        </p></v-col
      >
    </div>
    <!-- Formulario del cambio de contraseña -->
    <v-form
      ref="change-password-form"
      form="change-password-form"
      id="change-password-form"
      v-model="isFormPasswordValid"
      class="pa-md-2 mx-lg-auto text-center"
      v-if="changePassword"
      @submit.prevent="changePasswordForm()"
    >
      <v-text-field
        outlined
        tabindex="1"
        dense
        label="Nueva contraseña"
        :append-icon="newPassword ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="() => (textType = !textType)"
        :type="textType ? 'password' : 'text'"
        :rules="
          newPassword != null
            ? rules.password.concat(
                repeatNewPassword != null
                  ? [rules.samePassword(newPassword, repeatNewPassword)]
                  : []
              )
            : []
        "
        v-model="newPassword"
        @focus="inputActive = 'newPassword'"
        @blur="inputActive = ''"
      ></v-text-field>
      <v-text-field
        outlined
        dense
        tabindex="2"
        label="Repetir contraseña"
        :append-icon="repeatNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="() => (textType = !textType)"
        :type="textType ? 'password' : 'text'"
        :rules="
          repeatNewPassword != null
            ? rules.required.concat([
                rules.samePassword(newPassword, repeatNewPassword),
              ])
            : []
        "
        v-model="repeatNewPassword"
        @focus="inputActive = 'repeatNewPassword'"
        @blur="inputActive = ''"
      ></v-text-field>
      <v-btn
        color="primary"
        type="submit"
        :loading="isLoading"
        :disabled="!isFormPasswordValid"
        form="change-password-form"
        >Confirmar</v-btn
      >
    </v-form>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
export default {
  name: "FormLogin",
  data: () => ({
    show1: false,
    rules: rules,
    password: "",
    validationError: false,
    error: false,
    isLoading: false,
    isFormPasswordValid: false,
    user: {
      username: "",
      password: "",
      userValidatedId: null,
    },
    newPassword: null,
    repeatNewPassword: null,
    textType: String,
    token: null,
    changePassword: false,
    userGenerateQR: false,
    userTokenRequest: false,
    appSelected: null,
    qrCode: null,
    isTokenFormValid: false,
    tokenError: false,
    isLoadingTokenBtn: false,
  }),
  computed: {
    ...mapGetters({
      loginUser: "user/login",
    }),
  },
  watch: {
    loginUser(val) {
      this.loginUserHandler(val);
    },
  },
  methods: {
    ...mapActions({
      userHasToChangePassword: "user/userHasToChangePassword",
      changeUserPassword: "user/changeUserPassword",
      userRequireTwoFactorAuthentication:
        "user/userRequireTwoFactorAuthentication",
      generateUserQR: "user/generateUserQR",
      validateUserAndReturnId: "user/validateUserAndReturnId",
      verifyTwoFactorAuthentication: "user/verifyTwoFactorAuthentication",
      setAlert: "user/setAlert",
    }),
    backToLogin() {
      this.token = this.appSelected = null;
      this.isLoading =
        this.isLoadingTokenBtn =
        this.userTokenRequest =
        this.tokenError =
        this.userGenerateQR =
          false;
    },
    goToAuthenticateCode() {
      this.userTokenRequest = true;
      this.userGenerateQR = false;
    },
    async loginUserHandler(val) {
      if (val?.validationError) {
        this.validationError = true;
        this.isLoading = false;
      } else this.validationError = false;
      if (val?.error) {
        this.error = true;
        this.isLoading = false;
      } else this.error = false;
      //si esto es true, ya inicio sesion mediante connect/token
      if (val?.access_token) {
        const hasToChangePassword = await this.userHasToChangePassword();
        if (hasToChangePassword) {
          // Guardo momentáneamente el token hasta que cambie la contraseña
          // para que no pueda acceder a las otras pantallas por ruta sin cambiar la contraseña
          const token = localStorage.getItem("token");
          // Limpio el token momentáneamente
          localStorage.setItem("token", null);
          // Lo guardo en una variable para setearlo una vez que ingrese las nuevas contraseñas
          this.token = token;
          this.changePassword = true;
          this.userTokenRequest = false;
          this.userGenerateQR = false;
          this.isLoading = false;
        } else {
          window.location.reload();
          this.$router.push({ name: "Home" });
        }
      } else {
        this.user.password = "";
        this.isLoading = false;
      }
    },
    async loginForm() {
      this.isLoading = true;
      if (this.$refs["login-form"].validate()) {
        try {
          this.user.userValidatedId = await this.validateUserAndReturnId({
            username: this.user.username,
            password: this.user.password,
          });
          const authType = await this.userRequireTwoFactorAuthentication(
            this.user.userValidatedId
          );
          if (authType === "QR") {
            //en este caso se debe generar el qr y leerlo para luego ingresar el token
            const qrCode = await this.generateUserQR(this.user.userValidatedId);
            this.qrCode = qrCode;
            this.userGenerateQR = true;
          } else if (authType === "QRCode") {
            //aca ya se leyo el qr, solo debe ingresar el token
            this.userTokenRequest = true;
            // no se requiere 2fa
          } else await this.normalLogin();
        } catch (error) {
          this.loginError();
        }
      } else {
        this.loginError();
      }
    },
    async changePasswordForm() {
      this.isLoading = true;
      // Seteo el token para que pueda hacer la llamada al endpoint
      localStorage.setItem("token", this.token);
      try {
        const response = await this.changeUserPassword(this.newPassword);
        if (response.status === 200) {
          this.setAlert({
            type: "success",
            message: "Contraseña actualizada con éxito.",
          });
        }
        window.location.reload();
        this.$router.push({ name: "Home" });
      } catch (error) {
        this.isLoading = false;
      }
    },
    async normalLogin() {
      try {
        const userData = {
          user: this.user.username,
          password: this.user.password,
          grand_type: "password",
          client_id: "incidentes",
        };
        await this.$store.dispatch("user/setLogin", userData);
      } catch (error) {
        this.loginError();
      }
    },
    async loginError() {
      this.validationError = true;
      this.error = true;
      this.isLoading = false;
      setTimeout(() => {
        this.user.password = "";
        this.error = false;
      }, 5000);
    },
    async tokenForm() {
      this.isLoadingTokenBtn = true;
      let response;
      try {
        const request = {
          userId: this.user.userValidatedId,
          code: this.token,
          userName: this.user.username,
        };
        response = await this.verifyTwoFactorAuthentication(request);

        if (response) {
          // Si ingresa el token correctamente seteo en true la bandera de validación y lo hago pasar por el método de login
          this.normalLogin();
        } else {
          // Si el token es incorrecto lanzo la alerta y no lo dejo ingresar
          this.isLoadingTokenBtn = false;
          this.tokenError = true;
        }
      } catch (error) {
        this.isLoadingTokenBtn = false;
      }
    },
    validate() {
      this.$refs.form.validate();
    },
  },
};
</script>
